import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import CookieModal from "../views/CookieModal/CookieModal";
import "../home2.css";

import Navbar from "./backend/NavBackend_test";

import Home from "../views/home/home";

import Footer from "../footer/footer";

function Home2() {
  const [cookieAccepted, setCookieAccepted] = useState(false);
  const [cookieRejected, setCookieRejected] = useState(false);

  useEffect(() => {
    const cookiesAccepted = Cookies.get("cookiesAccepted") === "true";
    if (cookiesAccepted) {
      setCookieAccepted(true);
    }
  }, []);

  const handleAcceptCookies = () => {
    setCookieAccepted(true);
    Cookies.set("cookiesAccepted", "true", { expires: 15 });
  };

  const handleRejectCookies = () => {
    setCookieRejected(true);
  };
  return (
    <div className="App">
      <Navbar />
      {cookieRejected ? (
        <div className="cookie-rejection">
          <p>
            You have declined cookies. Some features of this site may not work
            as intended.
          </p>
        </div>
      ) : (
        <>
          <Home />
        </>
      )}
      {!cookieAccepted && !cookieRejected && (
        <CookieModal
          onAccept={handleAcceptCookies}
          onReject={handleRejectCookies}
        />
      )}

      <Footer />
    </div>
  );
}

export default Home2;
