import React from 'react'
import step_count from "../../img/step_count.webp";
import tracking from "../../img/tracking.webp";
import better_result from "../../img/result.webp";
import graph_ranking from "../../img/graph_ranking.webp";
import feed_back from "../../img/feed_back.webp";
import automate_input from "../../img/automate_input.webp";

function Home2() {
  return (
    <div className="home2">
        <div className="content-detail convert-div margin-top-content">
          <div className="image-section">
            <img
              src={step_count}
              alt="Exercise"
              className="image image-margin-top padding-left-first-div"
            />
          </div>
          <div className="text-section">
            <div className="text-home2-1">
              <p className="header-home2-1 color-1149E6">
                Steps count and GPS tracking for Exercise.
              </p>
              <ul className="ul-home2-1 text-home2-detail">
                <li>
                  ก้าวเดินนั้น แสดงถึงการเคลื่อนไหวร่างกาย และ
                  ส่งผลต่อการใช้พลังงาน และเป็นการส่งเสริมสุขภาพแบบที่
                  “ไม่ใช่การออกกำลังกาย” Non-Exercise
                  ซึ่งสอดคล้องกับเป้าหมายของแอพพลิเคชั่น
                </li>
                <li>
                  การเคลื่อนไหวตามระยะทาง
                  เป็นอีกรูปแบบหนึ่งที่สามารถใช้ชี้วัดการใช้พลังงาน และ
                  ส่งเสริมสุขภาพเช่นเดียวกับการนับก้าวเดิน
                  ต่างกันทีรูปแบบการบันทึกและประเภทกิจกรรม เช่น การวิ่ง,
                  ปั่นจักรยาน ซึ่งไม่ได้ถูกวัดได้ด้วยข้อมูล ก้าวเดิน
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="content-detail">
          <div className="text-section">
            <div className="text-home2-2 padding-left-first-div">
              <p className="header-home2-2 color-1149E6">{`Tracking your target > Complete your Tasks`}</p>
              <p className="text-home2-detail">
                ข้อมูลของ API นั้นจะถูกนำมาใช้ใน กิจกรรมส่งเสริมสุขภาพ
                ที่กำหนดขึ้นเช่น การใช้ตัวแปรของ ก้าวเดิน/ระยะทาง
                เพื่อแสดงผลปัจจุบัน เทียบกับ Goal ที่ได้ถูกสร้างขึ้น ในกิจกรรม
                ซึ่งส่งผลให้เกิดการเพิ่มการเคลื่อนไหว และออกกำลังกายสำหรับ User
              </p>
            </div>
          </div>
          <div className="image-section">
            <img src={tracking} alt="Tracking" className="image image-margin-top" />
          </div>
        </div>
        <div className="content-detail margin-bottom-content">
          <div className="image-section no-image">
            <img
              src={better_result}
              alt="Better Result"
              className="image image-margin-top padding-left-first-div"
            />
          </div>
          <div className="text-section">
            <div className="text-home2-1">
              <p className="header-home2-3 color-1149E6">{`Better input > Better Result`}</p>
              <div className="detail-home2-3">
                <div className="image-home2-3 middle">
                  <img
                    src={graph_ranking}
                    alt="Graph Ranking"
                    className="image"
                  />
                </div>
                <div className="text-home2-3">
                  <ol className="text-detail-home2-3">
                    <li value="1">
                      WELLLY ได้ใช้ Input ของแต่ละ User เพื่อเทียบกับ Criteria
                      ที่กำหนด สำหรับ
                    </li>
                  </ol>
                  <ul className="text-home2-detail">
                    <li>การวัด Progression</li>
                    <li>การวัดผล Complete/Incomplete ของ Tasks</li>
                    <li>การเทียบลำดับ Ranking ของ Users</li>
                  </ul>
                </div>
              </div>
              <div className="detail-home2-3">
                <div className="image-home2-3 middle">
                  <img src={feed_back} alt="Feedback" className="image" />
                </div>
                <div className="text-home2-3">
                  <ol className="text-detail-home2-3">
                    <li value="2">
                      ใช้เป็น Feedback/Interface สำหรับ Users
                      ในการมอบคำแนะนำข้อมูล และ พัฒนาการของตนเอง
                    </li>
                  </ol>
                </div>
              </div>
              <div className="detail-home2-3">
                <div className="image-home2-3 middle">
                  <img
                    src={automate_input}
                    alt="Automate Input"
                    className="image"
                  />
                </div>
                <div className="text-home2-3">
                  <ol className="text-detail-home2-3">
                    <li value="3">
                      ใช้เพื่อ Validate ว่าเกิดกิจกรรมนั้นๆ ขึ้นจริง
                      ผ่านการเก็บข้อมูลแบบอัตโนมัติของ Device
                      เพื่อความน่าเชื่อถือของ Input
                      ว่าได้เกิดขึ้นจากกระบวนการที่ Automate ไม่ใช่ Input
                      Manually
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default Home2