import React from "react";
import wellly from "../img/icon/wellly_white.webp";
import phone from "../img/icon/phone-line.webp";
import mail from "../img/icon/mail-line.webp";
import facebook from "../img/icon/facebook-box-line.webp";
/* import horizon from "../img/icon/LineHorizon.webp"; */

import "./footer.css";

function Footer() {
  const handleTelClick = (tel) => {
    window.location.href = `tel:${tel}`;
  };

  const handleMailClick = (email) => {
    window.location.href = `mailto:${email}`;
  };

  const handleWebClick = (url) => {
    window.open(url, '_blank');
  };
  return (
    <div>
      <div className="footer-contact middle">
        <div className="contact-detail middle">
          <img
            src={wellly}
            alt="wellly"
            className="image logo-wellly"
          />
          <p className="bbpf">{`บริษัท บีบีพีเอฟ จำกัด (สำนักงานใหญ่)`}</p>
          <p className="bbpf-address">
            เลขที่ 1/2 ถ.พระรามที่2 ซอย 82 แยก 6 แขวงแสมดำ เขตบางขุนเทียน
            กรุงเทพ 10150
          </p>
          <div className="contact-us-footer middle">
            <p className="text-contact-us margin-bottom-right">ติดต่อเรา:</p>
            <div className="margin-bottom-right">
              <button onClick={() => handleTelClick('093-883-2339')} className="text-contact middle">
                <img
                  src={phone}
                  width={24}
                  height={24}
                  alt="phone"
                  className="image image-margin-bottom-right"
                />{" "}
                093-883-2339
              </button>
            </div>
            <div className="margin-bottom-right">
              <button onClick={() => handleMailClick('Thongchai@wellly.co')} className="text-contact middle">
                <img
                  src={mail}
                  width={24}
                  height={24}
                  alt="mail"
                  className="image image-margin-bottom-right"
                />{" "}
                Thongchai@wellly.co
              </button>
            </div>
            <div className="margin-bottom-right">
              <button  onClick={() => handleWebClick('https://www.google.co.th')} className="text-contact middle">
                <img
                  src={facebook}
                  width={24}
                  height={24}
                  alt="facebook"
                  className="image image-margin-bottom-right"
                />{" "}
                Wellly
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="policy-banding middle">
        <p className="license-policy">© 2022 BBPF RIGHTS RESERVED</p>
        <div className="text-cookie-policy">
        <img src={horizon} width={1} height={28} alt="" className="image image-horizon no-image" />
        <button onClick={() => handleWebClick('../../privacy')} className="text-policy">Privacy Policy</button>
        <img src={horizon} width={1} height={28} alt="" className="image image-horizon" />
        <button className="text-policy">Cookies Policy</button>
        <img src={horizon} width={1} height={28} alt="" className="image image-horizon" />
        <button className="text-policy">Cookies Setting</button>
        </div>
        
      </div> */}
    </div>
  );
}

export default Footer;
