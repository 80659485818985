import React from "react";
import "./CookieModal.css";
import cookie from "../../img/icon/cookie.webp";

const CookieModal = ({ onAccept, onReject }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <img src={cookie} alt="" className="image image-cookie" />
        <p>
          We use cookies to improve your experience. By using our site, you
          agree to our use of cookies.
        </p>
        <div className="modal-buttons">
          <button onClick={onAccept}>Accept Cookies</button>
          <button onClick={onReject}>Reject Cookies</button>
        </div>
      </div>
    </div>
  );
};

export default CookieModal;
