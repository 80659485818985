import React from "react";
import iphone from "../../img/iphone_app.webp";
import mac from "../../img/mac_web.webp";

function Home4() {
  return (
    <div className="home4 margin-top-content">
      <p className="header-home4">Appendix</p>
      <div className="image-home4">
        <img src={iphone} alt="iphone" className="image" />
      </div>
      <div className="image-home4">
        <img src={mac} alt="mac" className="image" />
      </div>
    </div>
  );
}

export default Home4;
