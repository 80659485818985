import React from 'react'
import logo from "../../img/Logo_web.webp";
import home_1 from "../../img/description_pic.webp";

function Home1() {
  return (
    <div className="home1">
        <div className="content-home1">
          <div className="div-text-home1">
            <img src={logo} alt="Welly Logo" className="wellly-home1" />
            <p className="text-home1 font-size-24 H3">
              แอพพลิเคชั่นที่ช่วยส่งเสริมการออกกำลังกาย ของผู้ใช้
              ผ่านกิจกรรมเสมือน Virtual Activities
            </p>
            <p className="text-home1 font-size-20">
              ซึ่งใช้ การนับก้าวเดิน และ การออกกำลังกายแบบวัดระยะทางด้วย GPS
              เป็นข้อมูลตัวแปรของ Criteria สำหรับกิจกรรม
            </p>
          </div>
          <div>
            <img src={home_1} alt="Fitness" className="image" />
          </div>
        </div>
      </div>
  )
}

export default Home1;