import React from "react";
import Home1 from "./home1";
import Home2 from "./home2";
import Home3 from "./home3";
import Home4 from "./home4";

function LandingPage() {
  return (
    <>
      <Home1 />
      <Home2 />
      <Home3 />
      <Home4 />
    </>
  );
}

export default LandingPage;
